import React, {useContext} from "react";
import Routes from "./Routes";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import "./App.scss";

import { AuthContext } from './context/Auth.context.js';
import Login from './Login';

const App = () => {
  const { state } = useContext(AuthContext);

  if (!state.isLoggedIn) 
    return <Login />;
  else
    return (
    <div>
      <Navbar />  
      <Routes />
      <Footer />
    </div>  
    )
}

export default App;
