import React, { useContext } from 'react';
import { AuthContext } from '../../context/Auth.context.js';
import logo from "../../Assets/logo.png";
import { Link } from "react-router-dom";
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import "./index.scss";

const Home = () => {
  const { logout } = useContext(AuthContext);
  const onLogout = (e) => {
    e.preventDefault();
    logout();
  }
//   const { state } = useContext(AuthContext);
//   const codAnac = state['anac']

  return (
  <div>  
   <Navbar variant="" expand="lg">
       <Container >
           <Link to={'/'}>
               <Navbar.Brand>
               <img
                   src={logo}
                   width="100"
                   className="Navbarlogo"
                   alt="PR"
               />            
               </Navbar.Brand>                
           </Link>
           <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
           <Nav > 
               <Link to={'/'}>
                   <Button variant="link" className="nav-link">Inicio</Button>
               </Link>  
               <Link to={'/listvoomes'}>
                   <Button variant="link" className="nav-link">Voos no Mes</Button>
               </Link>                                  
               <Link to={'/listvoos'}>
                   <Button variant="link" className="nav-link">Listar Voos</Button>
               </Link>                 
               <Link to={'/login'}>
                   <Button variant="link" className="nav-link" onClick={onLogout}>Sair</Button>
               </Link>                                                                                           
           </Nav>
           </Navbar.Collapse>                                                            
       </Container>   
       <Navbar.Toggle aria-controls="basic-navbar-nav" />
   </Navbar>    
</div>    
  );
}

export default Home;