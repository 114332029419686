import React, {useContext} from 'react';
import { useSetState } from 'react-use';
import { Container } from "react-bootstrap";
import { AuthContext } from '../context/Auth.context.js';
import logo from "../Assets/logo.png";
import "./index.scss";
const initialState = {
  email: '',
  password: ''
}

const LoginForm = () => {
  const { state: ContextState, login } = useContext(AuthContext);
  const {
    isLoginPending,
    isLoggedIn,
    loginError
  } = ContextState;
  const [state, setState] = useSetState(initialState);

  const onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = state;
    login(email, password);
    setState({
      email: '',
      password: ''
    });
  }
    
  return (
    <Container>
            <div className='logo'>
                <img
                    src={logo}
                    width="150"
                    className="logo-info"
                    alt="Heringer"
                />                
            </div> 
        <div id="formlogin">
            <h1>Login do Sistema</h1>           
            <form name="loginForm" onSubmit={onSubmit}>

            <div className="form">
                
                <div className="field">
                    <label htmlFor="email">Cod Anac</label>                    
                    <input 
                        type="text" 
                        name="email" 
                        onChange={e => setState({email: e.target.value})} 
                        value={state.email} 
                        placeholder="Digite o seu código ANAC" 
                    />
                </div>

                <div className="field">
                    <label htmlFor="password">Senha</label>
                    <input 
                    type="password" 
                    name="password" 
                    onChange={e => setState({password: e.target.value})} 
                    value={state.password} 
                    placeholder="Digite sua Senha" 
                    />
                </div>

                <div className="actions">
                <button className="primary" type="submit" value="Login">Entrar</button>
                </div>
                
            </div>

            { isLoginPending && <div>Por favor aguarde...</div> }
            { isLoggedIn && <div>Success.</div> }
            { loginError && <div>{loginError.message}</div> }
            </form>
        </div>
    </Container>
  )
}


export default LoginForm;