import { Route, Routes } from "react-router-dom";
import Home from "../Views/Home";
import Login from "../Login";
import Listvoos from "../Views/Listvoos";
import Listvoomes from "../Views/Listvoomes";

export default function Rotas() {
    return(
            <Routes>
                <Route path="/" element={<Home /> } />
                <Route path="/login" element={ <Login />} />
                <Route path="/Listvoos" element={ <Listvoos />} />
                <Route path="/Listvoomes" element={ <Listvoomes />} />
            </Routes>
    );
}