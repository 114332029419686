import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { AuthContext } from '../../context/Auth.context.js';
import axios from 'axios';
import "./index.scss";

const Listvoos = () => {
    const { state } = useContext(AuthContext);
    const codAnac = state['anac'];
    const [dtinicio, setDtinicio] = useState('');
    const [dtfim, setDtfim] = useState('');
    const [listvoo, setListvoo] = useState([]);
    const [erro, setErro] = useState(false);
    let [aQttotal, setAQttotal] = useState([0]);

    const onSubmit = (e) => {
    if (dtinicio !== '' && dtfim !== '' ) {  
      e.preventDefault();  
     //177.185.131.250:60012
     //10.0.0.16:8012
        axios.get('http://177.185.131.250:8012/rest/RELVOO?Dtinicio=' + convertdata(dtinicio) + '&Dtfim=' + convertdata(dtfim) + '&CodAnac=' + codAnac)
            .then(response => {
                if (response.data.length <= 0){
                    setErro(false);
                    setListvoo([Relvooskm => ['id',0]]);
                    setAQttotal([0])
                    alert("Nada encontrado para o período informado!")
                }else{
                    setErro(true);
                    setAQttotal([0])
                    setListvoo(response.data.Relvooskm);                  
                }                                   
            });        
      }else {
        e.preventDefault(); 
        alert("Preencher a faixa de datas!")
      };
    }; 
    useEffect( () => {
        if(aQttotal[0] === 0){
            async function subtotal() {
                if (erro) {
                    setAQttotal(totalcalc(listvoo));
                }
            } 
            subtotal();
        }
      })       
    return(
        <Container>
            <form name="ListvoosForm" onSubmit={onSubmit}>             
                <div className="input-group mb-3 pt-2 d-flex justify-content-center">
                    <div className="">
                        <label className="form-label">Data inicial</label>
                        <input type="date" className="form-control input-date" placeholder="Data inicial" aria-label="Data inicial"  onChange={e => setDtinicio(e.target.value)} value={dtinicio}  />
                    </div>
                    <div className="ps-2">
                        <label className="form-label">Data final</label>
                        <input type="date" className="form-control input-date" placeholder="Data final" aria-label="Data final" onChange={e => setDtfim(e.target.value)} value={dtfim} />
                    </div>
                    <div className="ps-2 align-self-end">
                        <button type="submit" value="Login" className="btn btn-success">Buscar</button>
                    </div>
                </div>
            </form>
            <div>    
                <Table striped bordered hover variant="light" responsive className="tabela">
                <thead>
                    <tr>
                    <th>Id</th>
                    <th>Dt_do_voo</th>
                    <th>Prefixo</th>
                    <th>Comando</th>
                    <th>Origem</th>
                    <th>Destino</th>
                    <th>1 KM_Voados</th>
                    <th>2 Valor_KM</th>
                    <th>3 Valor_DI_Ut</th>
                    <th>4 Vlr_p_DI_Ut</th>
                    <th>5 Vlr_DI_Ut_N</th>
                    <th>6 Vlr_DI_Ut_P_N</th>
                    <th>7 Valor_DF</th>
                    <th>8 Valor_p_DF</th>
                    <th>9 Valor_DFN</th>
                    <th>10 Valor_p_DFN </th>
                    <th>11 Valor_Total</th>                
                    </tr>
                </thead> 
                <tbody> 
                {erro &&
                listvoo.map(list => ( 
                        <tr key={list.id}>
                        <td>{list.id}</td>
                        <td>{list.Data}</td>
                        <td>{list.Prefixo}</td>
                        <td>{list.Tp}</td>
                        <td>{list.CmOrig}</td>
                        <td>{list.CmDest}</td>
                        <td>{list.KMvoo}</td> 
                        <td>R$ {list.Vlkm.toFixed(2)}</td>  
                        <td>R$ {list.Vlkmtotal}</td>
                        <td>R$ {list.Pvlkmtota}</td> 
                        <td>R$ {list.Vlkmutnotu}</td>  
                        <td>R$ {list.Pvlkmutnot}</td>   
                        <td>R$ {list.Vlkmdf}</td>  
                        <td>R$ {list.Pvlkmdf}</td>
                        <td>R$ {list.Vlkmdfn}</td> 
                        <td>R$ {list.Pvlkmdfn}</td>  
                        <td>R$ {list.Vltotlg}</td>                                                      
                        </tr> 
                ))}          
                </tbody>
            </Table>
        </div>
        <div>
            <h6 className="mt-3">Legendas para colunas do relatório detalhado</h6>
            <Row xs={2} md={4} className="mt-1 mb-2">
                <Col className="mt-2 col-style">1 KM voados no trecho</Col>
                <Col className="mt-2 col-style">2 Valor por KM</Col>
                <Col className="mt-2 col-style">3 Valor total do trecho em dias úteis</Col>
                <Col className="mt-2 col-style">4 valor de periculosidade em dias úteis</Col>
                <Col className="mt-2 col-style">5 valor total em dias úteis noturno</Col>
                <Col className="mt-2 col-style">6 valor de periculosidade em dias uteis noturno</Col>
                <Col className="mt-2 col-style">7 valore domingo e feriados</Col>
                <Col className="mt-2 col-style">8 valor de periculosidade domingo e feriado</Col>
                <Col className="mt-2 col-style">9 valor de domingo e feriado noturno</Col>
                <Col className="mt-2 col-style">10 valor periculosidade domingos e feriados noturno</Col>
                <Col className="mt-2 col-style">11 somatório total do trecho</Col>
                </Row>
            </div>        
        </Container>

    )
};

export default Listvoos;

const convertdata = (dt) => {
    const data = String(dt);
    const novaData = data.replace(/-/g, "");
    return  novaData;
}

const totalcalc = (listvoo) => {
    let ntotal = 0
    let nVltotp = 0
    let nVltot = 0
    let nVltotn = 0
    let nVltotnp = 0
    let nVltotdf = 0
    let nVltotdfp = 0
    let nVltotdfn = 0
    let nVltotdfnp = 0
    let nVltotlg = 0    
    let aQt = []
    listvoo.map(list => ( (
        ntotal += list.KMvoo,
        nVltot += list.Vlkmtotal,
        nVltotp += list.Pvlkmtota,
        nVltotn += list.Vlkmutnotu,
        nVltotnp += list.Pvlkmutnot,
        nVltotdf += list.Vlkmdf,
        nVltotdfp += list.Pvlkmdf,
        nVltotdfn += list.Vlkmdfn,
        nVltotdfnp += list.Pvlkmdfn,
        nVltotlg += list.Vltotlg        
))) 
aQt = [1,ntotal.toFixed(2),nVltot.toFixed(2),nVltotp.toFixed(2),nVltotn.toFixed(2),nVltotnp.toFixed(2), nVltotdf.toFixed(2),nVltotdfp.toFixed(2),nVltotdfn.toFixed(2),nVltotdfnp.toFixed(2),nVltotlg.toFixed(2)  ];
return aQt 
}