import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from './context/Auth.context.js';
import reportWebVitals from './reportWebVitals';

import App from './App'
import "./styles.scss";


function AppWithProvider() {
  return (
    <ContextProvider value={500}>  
      <BrowserRouter>
        <App />    
      </BrowserRouter>    
    </ContextProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWithProvider />);


reportWebVitals();


