import React from 'react';
import { useSetState } from 'react-use';
import axios from 'axios';

export const AuthContext = React.createContext(null);
const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
}

export const ContextProvider = props => {
  const [state, setState] = useSetState(initialState);
  const setLoginPending = (isLoginPending) => setState({isLoginPending});
  const setLoginSuccess = (isLoggedIn) => setState({isLoggedIn});
  const setLoginError = (loginError) => setState({loginError});
  const setAnac = (anac) => setState({anac});

  const login = (email, password) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);
    setAnac(email);

    fetchLogin( email, password ,error => {
      setLoginPending(false);
      if (!error) {
        setLoginSuccess(true);
      } else {
        setLoginError(error);
      }
    })
  }

  const logout = () => {
    setLoginPending(false);
    setLoginSuccess(false);
    setLoginError(null);
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        logout
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

// login
const fetchLogin = (email, password ,callback) => 
    setTimeout(() => {
        let chave = '';
        //177.185.131.250:60012
        //10.0.0.16:8012
        axios.get('http://177.185.131.250:8012/rest/PILOTOLOGIN?CodAnac=' + email + '&Senha=' + password)
            .then(response => {
                //setInformacoes(response.data);
                chave = response.data['token'];
                 if (chave === 'HeringerLogin2023-sim-liberado+o-piloto') {
                    return callback(null);
                  } else {
                    return callback(new Error('Usuario ou senha invalido!'));
                  }                 
            })
            .catch((error) => {
              alert("Ocorreu um erro ao buscar as informações!");
              return callback(new Error('Tente novamente!'));
            });
  }, 10);