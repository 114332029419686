import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AuthContext } from '../../context/Auth.context.js';
import { FcNews,FcDocument } from "react-icons/fc";
import { Link } from "react-router-dom";
import "./index.scss";
//import logo from "../Assets/logo.png";


const Home = () => {
  
  const { state } = useContext(AuthContext);
  const codAnac = state['anac'];

  return (
    <div> 
        <Container>
          <h1 className='d-flex align-itens-center'>Código Anac: {codAnac}</h1>
          <Row xs={2} md={2}>
              <Col>
                <Link to={'/listvoomes'}>
                  <h1><FcNews className="me-3" color="#B8860B"/></h1>
                </Link>              
                <h5>Voos por Mês</h5>
              </Col>
              <Col>
                <Link to={'/listvoos'}>
                  <h1><FcDocument className="me-3" color="#B8860B"/></h1>
                </Link>              
                <h5>Voos por periodo</h5>
              </Col>              
          </Row>
        </Container>       
    </div> 
  );
}

export default Home;